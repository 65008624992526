import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl, useIntl } from 'react-intl';
import { css } from '@emotion/core';
import { PORTAL_URL } from '../../../utils/constants';
import styles from '../../../styles';
import LocalizedLink from '../../locale/LocalizedLink';
import Icon from '../../Icon';
import Hamburger from './Hamburger';
import Search from './Search';
import NavItems from '../nav/NavItems';
import { ButtonAnchor } from '../../Button';

const messages = defineMessages({
  consumers: {
    id: 'header.link.consumers',
    defaultMessage: 'Consumers',
  },
  suppliers: {
    id: 'header.link.suppliers',
    defaultMessage: 'Suppliers',
  },
  goToLink: {
    id: 'header.link.goToLink',
    defaultMessage: 'Go to PolySwarm',
  },
});

const navItems = [
  {
    id: 1,
    to: '/consumers',
    label: messages.consumers,
  },
  {
    id: 2,
    to: '/suppliers',
    label: messages.suppliers,
  },
];

class Header extends Component {
  state = { isSearchOpen: false, isNavOpen: false };

  render() {
    const { searchId, intl } = this.props;
    const { isSearchOpen, isNavOpen } = this.state;
    return (
      <header css={style.root}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div css={style.content}>
                <div css={style.hamburger(isSearchOpen, isNavOpen)}>
                  <Hamburger
                    isOpen={isNavOpen}
                    onClick={this._toggleNav}
                    ariaControls='js-mobile-nav'
                  />
                </div>
                <div css={style.header(isSearchOpen)}>
                  <MobileNav
                    id='js-mobile-nav'
                    isOpen={isNavOpen}
                    onClick={this._closeNav}
                    items={navItems}
                    intl={intl}
                  />
                  <MobileLogo
                    style={[style.logo, style.mobileLogo(isNavOpen)]}
                    onClick={this._closeNav}
                  />
                  <DesktopLogo style={style.logo} />
                  <DesktopNav items={navItems} />
                </div>
                <div css={style.search(isSearchOpen)}>
                  <Search id={searchId} onToggleSearch={this._toggleSearch} />
                </div>
                <ButtonAnchor
                  css={style.btn}
                  variant='light'
                  href={PORTAL_URL}
                  target='_blank'
                  rel='noopener noreferrer'>
                  {intl.formatMessage(messages.goToLink)}
                </ButtonAnchor>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

  _toggleSearch = (isSearchOpen) => {
    this.setState({ isSearchOpen });
  };

  _toggleNav = () => this.setState({ isNavOpen: !this.state.isNavOpen });

  _closeNav = () => this.setState({ isNavOpen: false });
}

Header.propTypes = {
  searchId: PropTypes.string,
};

const style = {
  root: css`
    position: fixed;
    z-index: 3;
    background-color: ${styles.color.white};
    border-bottom: 1px solid ${styles.border.color.grey};
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.8rem 0;
  `,
  content: css`
    position: relative;
  `,
  header: (isSearchOpen) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${isSearchOpen ? '0' : '1'};
    transition: opacity ${styles.easing.time} ${styles.easing.main};
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      opacity: 1;
      justify-content: space-between;
    }
  `,
  hamburger: (isSearchOpen, isNavOpen) => css`
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    left: ${isNavOpen ? 'calc(100% - 4rem)' : '0'};
    opacity: ${isSearchOpen ? '0' : '1'};
    visibility: ${isSearchOpen ? 'hidden' : 'visible'};
    transition: opacity ${styles.easing.time} ${styles.easing.main},
      left ${styles.easing.medTime} ${styles.easing.authenticMotion};
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      display: none;
    }
  `,
  logo: css`
    padding: 2rem 0 1rem;
    margin-bottom: -3rem;
    top: -2rem;
    position: relative;
    z-index: 1;
  `,
  mobileLogo: (isNavOpen) => css`
    background-color: ${isNavOpen ? styles.color.white : 'transparent'};
    transition: ${isNavOpen ? 'none' : 'background-color 0.3s ease-in-out 0.3s'};
  `,
  btn: css`
    font-size: 1.4rem !important;
    position: absolute;
    display: none;
    justify-content: flex-end;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      display: flex;
    }
  `,
  search: (isSearchOpen) => css`
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 50%;
    right: 0;
    width: ${isSearchOpen ? '100%' : 'auto'};
    transform: translateY(-50%);
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      width: 24rem;
      right: 16rem;
    }
  `,
};

const MobileNav = ({ id, isOpen, onClick, items, intl }) => (
  <nav id={id} css={mobileNavStyle(isOpen)}>
    <NavItems items={items} onClick={onClick} />
    <ButtonAnchor variant='light' href={PORTAL_URL} target='_blank' rel='noopener noreferrer'>
      {intl.formatMessage(messages.goToLink)}
    </ButtonAnchor>
  </nav>
);

const mobileNavStyle = (isOpen) => {
  // Currently only supports 50 nav items, though we shouldn't need more than this.
  const stagger = range(1, 51).reduce((acc, _, index) => {
    const i = index + 1;
    return `${acc}\n&:nth-of-type(${i}) { transition-delay: ${0.05 * i}s; }`;
  }, '');

  return css`
    text-align: center;
    opacity: ${isOpen ? '1' : '0'};
    visibility: ${isOpen ? 'visible' : 'hidden'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 1;
    background: ${styles.color.white};
    padding: 8rem ${styles.spacing.grid} 0;
    transition: opacity ${styles.easing.medTime} ${styles.easing.authenticMotion},
      visibility ${styles.easing.medTime} ${styles.easing.authenticMotion};
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      display: none;
    }

    li {
      opacity: ${isOpen ? '1' : '0'};
      margin-top: ${isOpen ? '0' : '1rem'};
      transition: opacity ${styles.easing.time} ${styles.easing.main},
        margin-top ${styles.easing.time} ${styles.easing.main};
      ${stagger}
    }

    a {
      display: inline-block;
      margin-bottom: ${styles.spacing.xs};
      font-weight: ${styles.font.weight.medium};
    }
  `;
};

const DesktopNav = ({ items }) => (
  <nav css={desktopNavStyle.root}>
    <NavItems items={items} />
  </nav>
);

const desktopNavStyle = {
  root: css`
    flex: 1;
    display: none;
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      display: flex;
    }
    li {
      display: inline;
    }
    a {
      margin-left: ${styles.spacing.sm};
      font-size: ${styles.font.size.p1};
      font-weight: ${styles.font.weight.medium};
    }
  `,
};

const MobileLogo = ({ style, onClick }) => (
  <LocalizedLink css={[mobileLogoStyle.root, style]} to='/' onClick={onClick}>
    <Icon style={mobileLogoStyle.icon} name='wordmark' title='PolySwarm' />
    <FormattedMessage id='company.name' defaultMessage='PolySwarm' />
  </LocalizedLink>
);

const mobileLogoStyle = {
  root: css`
    color: ${styles.color.purple};
    font-size: 0;
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      display: none;
    }
  `,
  icon: css`
    font-size: 0.8rem;
    @media (min-width: ${styles.breakpoint.xs}) {
      font-size: 1rem;
    }
    @media (min-width: ${styles.breakpoint.sm}) {
      font-size: 1.1rem;
    }
  `,
};

const DesktopLogo = ({ style }) => {
  const intl = useIntl();
  return (
    <LocalizedLink css={[desktopLogoStyle.root, style]} to='/'>
      <Icon style={desktopLogoStyle.icon} name='wordmark' title='PolySwarm' />
      {intl.formatMessage({ id: 'company.name' })}
    </LocalizedLink>
  );
};

const desktopLogoStyle = {
  root: css`
    color: ${styles.color.purple};
    font-size: 0;
    display: none;
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      display: block;
    }
  `,
  icon: css`
    font-size: 0.8rem;
    @media (min-width: ${styles.breakpoint.xs}) {
      font-size: 1rem;
    }
    @media (min-width: ${styles.breakpoint.sm}) {
      font-size: 1.1rem;
    }
  `,
};

export default injectIntl(Header);
